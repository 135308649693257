import React  from "react"

import './software-documentation.component.less';

import ArrowDown from "../../assets/img/icon/arrow_down.svg"
import SEO from "../seo";
import {useTranslation} from "react-i18next";
import {useI18next} from "gatsby-plugin-react-i18next";

const SoftwareDocumentationComponent = () => {
    const { i18n, t } = useTranslation()
    const { originalPath } = useI18next()

    function openAccardion() {
        let block = document.getElementById('dfs_acardion_wrapper');
        let arrows = document.getElementById('dfs_arrow_down');
        let text = document.getElementById('dfs_btn_text');
        if (!block.classList.contains('documentation_for_software__acardion_wrapper-close')){
            block.classList.add('documentation_for_software__acardion_wrapper-close');
            arrows.classList.add('documentation_for_software__img_arrows_left_menu-close');
            text.innerHTML = 'Подробнее';
        } else {
            block.classList.remove('documentation_for_software__acardion_wrapper-close');
            arrows.classList.remove('documentation_for_software__img_arrows_left_menu-close');
            text.innerHTML = 'Свернуть';
        }
    }
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <SEO description={t("metaDescription")} lang={i18n.language} title={t("titleSoftwareDocumentation")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="wrapper_container_main wrapper_container_main-flex wrapper_container_main-documentation_for_software">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div className="documentation_for_software__main_name">
                                Документация на&nbsp;программное обеспечение (программы для ЭВМ)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper_container wrapper_container-gray">
                <div
                    className="container documentation_for_software_block_1__container">
                    <div className="row">

                        <div className="documentation_for_software__blocks"> 

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Библиотека подпрограмм для модуля анализа и расчета рассеяния загрязняющих веществ в атмосфере» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Данная библиотека подпрограмм предназначена для работы с дисперсионной моделью рассеивания загрязнения в атмосфере FLEXPART. В нее входит набор инструментов, необходимый для автоматизации запусков расчетов модели.
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text documentation_for_software__block_info_footer-text_border-bottom">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/User_manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство пользователя</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха CityAir.Basic» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Программный комплекс CityAir.Basic представляет собой сервис, предназначенный для сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха на картографической подоснове в режиме реального времени.
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/Exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система моделирования рассеивания пассивных примесей от источников CityAir.Plume» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                    Система моделирования рассеивания пассивных примесей от источников CityAir.Plume является модулем программного комплекса CityAir.Basic, который предназначен для сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха на картографической подоснове в режиме реального времени. 
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/plume/Exploitation-guide-CityAir.Plume.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">
                                        Программа для ЭВМ «Система расчета переноса химически активных примесей в атмосферном воздухе CityAir.Forecast» 
                                    </div>
                                    <div className="documentation_for_software__description">
                                        Система расчета переноса химически активных примесей в атмосферном воздухе CityAir.Forecast является модулем программного комплекса CityAir.Basic, который предназначен для прогнозирования концентраций загрязняющих веществ в воздухе на контролируемой территории (города, района) с учетом прогноза метеорологических условий. 
                                    </div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/forecast/Exploitation-guide-CityAir.Forecast.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация для эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">Программа для ЭВМ «Интеграционный шлюз CityAir.Gateway»</div>
                                    <div className="documentation_for_software__description">Интеграционный шлюз CityAir.Gateway это ПО, предназначенное для передачи данных измерений от оборудования CityAir и интегрированных в него модулей сторонних производителей по стандартным протоколам конечному Заказчику.</div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/gateway/CityAir-Gateway-Description-of-the-functional-characteristics.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Описание функциональных характеристик ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/gateway/CityAir-Gateway-Installation-instructions.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по установке</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/gateway/CityAir-Gateway-exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/gateway/CityAir-Gateway-Information-cost.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация по стоимости ПО</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">Программа для ЭВМ «Встроенное программное обеспечение CityAir Core Firmware 2.1.111.680</div>
                                    <div className="documentation_for_software__description">Программа предназначена для управления работой контроллера ядра микростанции мониторинга качества воздуха CityAir, являющегося головным устройством поста мониторинга, под управлением микроконтроллера EFM32GG380F1024.</div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/core/CityAir-Core-Firmware-Description-of-the-functional-characteristics.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Описание функциональных характеристик встраиваемого ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/core/CityAir-Core-Firmware-Installation-instructions.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по установке встраиваемого ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/core/CityAir-Core-Firmware-exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по эксплуатации встраиваемого ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/core/CityAir-Core-Firmware-Information-cost.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация по стоимости встраиваемого ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/core/CityAir-Station2-User-Manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство по эксплуатации головного устройства Станция CityAir 2</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">Программа для ЭВМ «Встроенное программное обеспечение CityAir Dust 1.0.0.84»</div>
                                    <div className="documentation_for_software__description">Встроенное программное обеспечение CityAir Dust 1.0.0.84 (далее ПО) предназначено предназначена для управления работой контроллера пылемера под управлением микроконтроллера EFM32HG222F64 в составе микростанции CityAir.</div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/dust/CityAir-Dust-Firmware-Description-of-the-functional-characteristics.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Описание функциональных характеристик ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/dust/CityAir-Dust-Firmware-Installation-instructions.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по установке</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/dust/CityAir-Dust-Firmware-exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/dust/CityAir-Dust-Firmware-Information-cost.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация по стоимости ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/dust/CityAir-Dust-Firmware-Manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство по эксплуатации CityAir Dust</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">Программа для ЭВМ «Встроенное программное обеспечение CityAir G1/G2 - ExtensionModule 1.1.111.680»</div>
                                    <div className="documentation_for_software__description">Программа предназначена для управления работой контроллера газовых модулей G1, G2 под управлением микроконтроллера EFM32GG380F1024.</div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G1-G2-Firmware-Description-of-the-functional-characteristics.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Описание функциональных характеристик ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G1-G2-Firmware-Installation-instructions.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по установке</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G1-G2-Firmware-exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G1-G2-Firmware-Information-cost.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация по стоимости ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G1-Firmware-Manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство по эксплуатации Модуль G1</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g1g2/CityAir-G2-Firmware-Manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство по эксплуатации Модуль G2</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="documentation_for_software__block_info">
                                <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xga-10 col-fhd-10 col-rt-10">

                                    <div className="documentation_for_software__sub_title">Программа для ЭВМ «Встроенное программное обеспечение CityAir G3A - G3HUB 1.1.113.256»</div>
                                    <div className="documentation_for_software__description">Встроенное программное обеспечение CityAir G3A - G3HUB 1.1.113.256 (далее ПО) предназначено для управления работой контроллера газового модуля G3 под управлением микроконтроллера EFM32GG280F512.</div>
                                    
                                    <div className="documentation_for_software__block_info_footer">
                                        <div className="documentation_for_software__block_info_footer-title">
                                            Документы:
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g3a/CityAir-G3A-Firmware-Description-of-the-functional-characteristics.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Описание функциональных характеристик ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g3a/CityAir-G3A-Firmware-Installation-instructions.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по установке</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g3a/CityAir-G3A-Firmware-exploitation-guide.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Инструкция по эксплуатации</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g3a/CityAir-G3A-Firmware-Information-cost.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Информация по стоимости ПО</span> (.pdf)
                                            </a>
                                        </div>
                                        <div className="documentation_for_software__block_info_footer-text">
                                            <a target="_blank" className="documentation_for_software__block_info_footer-link" download href="/docs/g3a/CityAir-G3A-Firmware-Manual.pdf">
                                                <span className="documentation_for_software__block_info_footer-link_text">Руководство по эксплуатации Модуль G3.A</span> (.pdf)
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SoftwareDocumentationComponent.propTypes = {}

SoftwareDocumentationComponent.defaultProps = {}

export default SoftwareDocumentationComponent
